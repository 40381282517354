import {apiUrl,authToken} from '../../connection/hellosign'
import url from '../../connection/url'
import axios from 'axios'
import { setStep2Valid } from './layoutActions'
import store from '../store'

export const GET_TEMPLATE_LIST_BEGIN = "GET_TEMPLATE_LIST_BEGIN"
export const GET_TEMPLATE_LIST_FAILURE = "GET_TEMPLATE_LIST_FAILURE"
export const GET_TEMPLATE_LIST_SUCCESS = "GET_TEMPLATE_LIST_SUCCESS"

export const SET_SELECTED_TEMPLATE = "SET_SELECTED_TEMPLATE"

export const SET_SELECTED_TEMPLATE_MAPPER_BEGIN = "SET_SELECTED_TEMPLATE_MAPPER_BEGIN"
export const SET_SELECTED_TEMPLATE_MAPPER_SUCCESS = "SET_SELECTED_TEMPLATE_MAPPER_SUCCESS"
export const SET_SELECTED_TEMPLATE_MAPPER_FAILURE = "SET_SELECTED_TEMPLATE_MAPPER_FAILURE"

export const GET_CLIENT_LIST_BEGIN = "GET_CLIENT_LIST_BEGIN"
export const GET_CLIENT_LIST_SUCCESS = "GET_CLIENT_LIST_SUCCESS"
export const GET_CLIENT_LIST_FAILURE = "GET_CLIENT_LIST_FAILURE"

export const SET_SELECTED_CLIENTS = "SET_SELECTED_CLIENTS"

export const UPDATE_REQUEST = "UPDATE_REQUEST"

export const SEND_SIGNING_REQUEST_BEGIN = "SEND_SIGNING_REQUEST_BEGIN"
export const SEND_SIGNING_REQUEST_SUCCESS = "SEND_SIGNING_REQUEST_SUCCESS"
export const SEND_SIGNING_REQUEST_FAILURE = "SEND_SIGNING_REQUEST_FAILURE"

export const SET_NEW_REQUEST = "SET_NEW_REQUEST"

export const SET_REQUEST_ERROR = "SET_REQUEST_ERROR"
export const CLEAR_ERROR = "CLEAR_ERROR"

export const BUILD_FINAL_REPORT = "BUILD_FINAL_REPORT"

export const SET_CLIENT_SEARCH_RESULTS = "SET_SEARCH_RESULTS"
export const SET_TEMPLATE_SEARCH_RESULTS = "SET_TEMPLATE_SEARCH_RESULTS"

export const SET_CLIENT_SEARCH_TEXT = "SET_CLIENT_SEARCH_TEXT"
export const SET_TEMPLATE_SEARCH_TEXT = "SET_CLIENT_SEARCH_TEXT"

export const SET_CLIENT_SEARCHER = "SET_CLIENT_SEARCHER"
export const SET_TEMPLATE_SEARCHER = "SET_TEMPLATE_SEARCHER"

export const SET_CLIENT_CHECK_ALL = "SET_CLIENT_CHECK_ALL"

export const SET_CLIENT_SEARCH_TIMER = "SET_CLIENT_SEARCH_TIMER"

export const setClientSearchTimer = (func) => {
  clearTimeout(store.getState().newRequest.clientSearchTimer)
  return {
    type: SET_CLIENT_SEARCH_TIMER,
    payload: func
  }
}

export const SetClientSearchText = (text) => ({
  type: SET_CLIENT_SEARCH_TEXT,
  payload: text
})

export const SetTemplateSearchText = (text) => ({
  type: SET_TEMPLATE_SEARCH_TEXT,
  payload: text
})

export const _setClientSearchResults = (res) => ({
  type: SET_CLIENT_SEARCH_RESULTS,
  payload: res
})

export const setTemplateSearchResults = (res) => ({
  type: SET_TEMPLATE_SEARCH_RESULTS,
  payload: res
})

export const setClientSearcher = (searcher) => ({
  type: SET_CLIENT_SEARCHER,
  payload: searcher
})

export const setTemplateSearcher = (searcher) => ({
  type: SET_TEMPLATE_SEARCHER,
  payload: searcher
})

export const _setClientCheckAll = (check) => ({
  type: SET_CLIENT_CHECK_ALL,
  payload: check
})

export const buildFinalReport = (arr) => ({
  type: BUILD_FINAL_REPORT,
  payload: arr
})

export const setRequestError = (err) => ({
  type: SET_REQUEST_ERROR,
  payload: err
})

export const setNewRequest = () => ({
  type: SET_NEW_REQUEST
})

export const clearError = () => ({
  type: CLEAR_ERROR
})

export const sendSigningRequestBegin = () => ({
  type: SEND_SIGNING_REQUEST_BEGIN
})

export const sendSigningRequestSuccess = (data) => ({
  type: SEND_SIGNING_REQUEST_SUCCESS,
  payload: data
})

export const sendSigningRequestFailure = (err) => ({
  type: SEND_SIGNING_REQUEST_FAILURE,
  payload: err
})

export const updateRequest = (req) => ({
  type: UPDATE_REQUEST,
  payload: req
})

export const setTemplate = (template) => ({
  type: SET_SELECTED_TEMPLATE,
  payload: template
})

export const setSelectedTemplateMapperBegin = () => ({
  type: SET_SELECTED_TEMPLATE_MAPPER_BEGIN
})

export const setSelectedTemplateMapperSuccess = (data) => ({
  type: SET_SELECTED_TEMPLATE_MAPPER_SUCCESS,
  payload: data
})

export const setSelectedTemplateMapperFailure = (err) => ({
  type: SET_SELECTED_TEMPLATE_MAPPER_FAILURE,
  payload: err
})

export const setSelectedClients = (arr) => ({
  type: SET_SELECTED_CLIENTS,
  payload: arr
})

export const getClientListBegin = () => ({
  type: GET_CLIENT_LIST_BEGIN
})

export const getClientListSuccess = (data) => ({
  type: GET_CLIENT_LIST_SUCCESS,
  payload: data
})

export const getClientListFailure = (err) => ({
  type: GET_CLIENT_LIST_FAILURE,
  payload: err
})

export const getTemplateListBegin = () => ({
  type: GET_TEMPLATE_LIST_BEGIN
})

export const getTemplateListSuccess = (data) => ({
  type: GET_TEMPLATE_LIST_SUCCESS,
  payload: data
})

export const getTemplateListFailure = (err) => ({
  type: GET_TEMPLATE_LIST_FAILURE,
  payload: err
})


export const setClientCheckAll = (check) => {
  return async dispatch => {
    let clients = store.getState().newRequest.clients
    if(clients && clients.length > 0) {
      // clients.forEach((_c) => {
      //   _c.checked = check
      // })
      clients.filter((_c) => _c.checked != check).forEach((_c) => {
        _c.checked = check
      })
      dispatch(getClientListSuccess(clients))
    }
    dispatch(_setClientCheckAll(check))
    dispatch(setStep2Valid(check))
  }
}



export const getTemplateList = () => {
  return async dispatch => {
    try {
      dispatch(getTemplateListBegin())
      const response = await axios({
        baseURL: `${url}/hellosign/template/list`,
        withCredentials: true,
        credentials: 'same-origin',
        method: 'get',
      })
      if(response.data) {
        if(response.data.templates) {
          dispatch(getTemplateListSuccess(response.data.templates))
        }else {
          dispatch(getTemplateListFailure('Unable to get list of Templates for signing request. If problem persists, please contact support.'))
        }
        // console.log('response: ', response)
      }else
        dispatch(getTemplateListFailure('Unable to get list of Templates for signing request. If problem persists, please contact support.'))
      // dispatch(getTemplateListFailure('Unable to get list of Templates for signing request. If problem persists, please contact support.'))
    }catch(error) {
      // console.log('getTemplateList error: ', error)
      dispatch(setRequestError('Unable to get list of Templates for signing request. If problem persists, please contact support.'))
    }
  }
}

export const setSelectedTemplate = (template) => {
  return async dispatch => {
    try {
      dispatch(setTemplate(template))
      if(template) {
        dispatch(setSelectedTemplateMapperBegin())
        let response = await axios({
          baseURL: `${url}/hellosign/template/mapper`,
          withCredentials: true,
          credentials: 'same-origin',
          method: 'post',
          data: {
            ID: template.template_id
          }
        })
        // console.log('response.data: ', response.data)
        if(response.data) {
          dispatch(setSelectedTemplateMapperSuccess(response.data))
        }else
          dispatch(setSelectedTemplateMapperFailure("Unable to locate the selected Template's Information. If problem persists, please contact support."))
      }else {
        //we don't have an error here.
      }
    }catch(error) {
      console.log('error')
    }
  }
}


export const getClientList = () => {
  return async dispatch => {
    try {
      dispatch(getClientListBegin())
      const response = await axios({
        baseURL: `${url}/accounts`,
        withCredentials: true,
        credentials: 'same-origin',
        method: 'get'
      })
      if(response.data) {
        // console.log('accounts: ', response.data)
        dispatch(getClientListSuccess(response.data))
      }
      // dispatch(getClientListFailure('Unable to get the Customer list. If problem persists, please contact support.'))
    }catch(err) {
      dispatch(getClientListFailure('Unable to get the Customer list. If problem persists, please contact support.'))
    }
  }
}

export const addClient = (client) => {
  return async dispatch => {
    try {
      let state = store.getState()
      
      let arr = [...(state.newRequest.selectedClients || []),client]
      dispatch(setSelectedClients(arr))
      // dispatch(updateClientLocal(_client))
    }catch(err) {
      console.log('addClient.err: ', err)
    }
  }
}


export const removeClient = (client) => {
  return async dispatch => {
    try {
      let state = store.getState()
      if(state.newRequest.selectedClients) {
        let _client = state.newRequest.selectedClients.find(_client => _client.Id == client.Id)
        //update client list
        // dispatch(updateClientLocal({...client,checked:false}))
        let arr = state.newRequest.selectedClients
        arr.splice(arr.indexOf(_client), 1)
        dispatch(setSelectedClients([...(arr || [])]))
      }
    }catch(err) {
      console.log('removeClient.err: ', err)
    }
  }
}

export const updateClientMasterListLocal = (client) => {
  // console.log('updateClientMasterListLocal :: ', client)
  return async dispatch => {
    try {
      let arr = store.getState().newRequest.clients
      if(arr) {
        let _client = arr.find(_client => _client.Id === client.Id)
        arr.splice(arr.indexOf(_client),1,client)
        
        dispatch(getClientListSuccess(arr))

        let _c = arr.find((_c) => _c.checked)
        // console.log("is checked found: ", _c)
        if(_c) dispatch(setStep2Valid(true))
        else dispatch(setStep2Valid(false))
      }
    }catch(err) {
      dispatch(setRequestError('Unable to update Customer Information. If problem persists, contact support.'))
    }
  }
}


export const updateClientLocal = (client) => {
  return async dispatch => {
    try {
      let arr = store.getState().newRequest.selectedClients
      if(arr) {
        let _client = arr.find(_client => _client.Id === client.Id)
        arr.splice(arr.indexOf(_client),1,client)
        let _arr = [...arr]
        dispatch(setSelectedClients(_arr))
      }
    }catch(err) {
      dispatch(setRequestError('Unable to update Customer Information. If problem persists, contact support.'))
    }
  }
}

export const updateClientLocalQuiet = (client) => {
  return async dispatch => {
    try {
      let arr = store.getState().newRequest.selectedClients
      if(arr) {
        let _client = arr.find(_client => _client.Id === client.Id)
        arr.splice(arr.indexOf(_client),1,client)
        // console.log('update client quiet: ', arr)
        dispatch(setSelectedClients(arr))
      }
    }catch(err) {
      dispatch(setRequestError('Unable to update Customer Information. If problem persists, contact support.'))
    }
  }
}

export const updateClient = (client,tag) => {
  return async dispatch => {
    try {
      let arr = store.getState().newRequest.selectedClients
      if(arr) {
        let _client = arr.find(_client => _client.Id === client.Id)
        arr.splice(arr.indexOf(_client),1,client)

        let response = await axios({
          baseURL: `${url}/accounts/update`,
          withCredentials: true,
          credentials: 'same-origin',
          method: 'post',
          data: {ACCT: client}
        })
        if(response.data) {
          if(response.data.UPDATE === true || response.data.UPDATE === 'true') {
            dispatch(setSelectedClients([...(arr || [])]))
          }else {
            console.log('unable to update client.')
          }
        }
      }
    }catch(err) {
      dispatch(setRequestError('Unable to update Customer Information. If problem persists, contact support.'))
    }
  }
}


export const sendSigningRequest = (newRequest,auth) => {
  return async dispatch => {
    try {
      if(newRequest.selectedTemplate && newRequest.selectedClients) {
        let _requests = []
        for(let _a in newRequest.selectedClients) {
          let _req = {
            ...newRequest.REQUEST,
            test_mode: 0,
            template_id: newRequest.selectedTemplate.template_id,
            signers: [{
              email_address: newRequest.selectedClients[_a].customerEmail,
              name: newRequest.selectedClients[_a].customerName,
              role: 'Customer'
            }],
            //ONLY ADD A CCS EMAIL TO THE CCS ARRAY IF IT EXISTS
            ccs: [{
              email_address: (newRequest.selectedClients[_a].ccEmail ? newRequest.selectedClients[_a].ccEmail : ""),
              role_name: 'GSS'
            }],
            custom_fields: getCustomFields(newRequest.selectedTemplate,newRequest.selectedClients[_a],newRequest.templateMapper),
            metadata: {
              account_id: newRequest.selectedClients[_a].Id,
              mapper_id: newRequest.templateMapper.Id,
              company_name: newRequest.selectedClients[_a].name,
              template_title: newRequest.selectedTemplate.title,
              sending_user_id: auth.AUTH.USR.Id,
              sender: `${auth.AUTH.USR.firstName} ${auth.AUTH.USR.lastName}`
            }
          }
          // console.log('_req: ', _req)
          _requests.push(_req)
        }

        if(_requests && _requests.length > 0) {
          // console.log('requests: ', _requests)
          let report = []
          for(let i in _requests) {
            let response = await axios({
              baseURL: `${url}/hellosign/send-requests`,
              withCredentials: true,
              credentials: 'same-origin',
              method: 'post',
              data: _requests[i]
            })
            if(response.data) {
              if(response.data.SENT === true) {
                report.push({
                  company: newRequest.selectedClients[i].name,
                  template: newRequest.selectedTemplate.title,
                  name: newRequest.selectedClients[i].customerName,
                  email: newRequest.selectedClients[i].customerEmail
                })
              } else if(response.data.ERR) {
                dispatch(setRequestError('Unable to send Signing Requests. If problem persists, contact support.'))
              }
            }
          }
          dispatch(buildFinalReport(report))
        }
      }
    }catch(err) {
      console.log('send request err: ', err)
      dispatch(setRequestError('Unable to send Signing Requests. If problem persists, contact support.'))
      dispatch(buildFinalReport([]))
    }
  }
}

/**
 * getCustomFields creates the array of customField objects to send to 
 * hellosign to pre-populate certain fields.
 * @param {*} _t The Template Object
 * @param {*} _c The current Customer Object
 * @param {*} _m The current TemplateMapper Object
 */
const getCustomFields = (_t,_c,_m) => {
  let _arr = []
  if(_t.custom_fields.length > 0) {
    for(let cf in _t.custom_fields) {
      // IF THE FIELD IS THE ADDRESS, WE HAVE TO CUSTOM BUILD THAT STRING.
      if(_t.custom_fields[cf].name === 'companyAddress1') {
        let _addr
        _addr = (_c.address || '') + ' ' + (_c.address2 || '')
        console.log('address: ', _addr)
        _arr.push({
          name:_t.custom_fields[cf].name,
          value:_addr
        })
      }else if(_t.custom_fields[cf].name === 'companyAddress2') {
        let _addr2
        _addr2 = (_c.city || '') + ', ' + (_c.state || '') + ' ' + (_c.zip || '') + ' ' + (_c.country || '')
        _arr.push({
          name:_t.custom_fields[cf].name,
          value: _addr2        
        })
      //THIS WILL CATCH PERCENT FIELD AND ALL OTHER NON-EDITABLE FIELDS BY THE CUSTOMER.
      }else if(_m.with_percent_value && _t.custom_fields[cf].name === 'renewalFee' || 
          (_t.custom_fields[cf].name !== 'customerName')) {
        _arr.push({
          name:_t.custom_fields[cf].name,
          value:_c[_t.custom_fields[cf].name] || _m[_t.custom_fields[cf].name]   //IF THEY DIDN'T EDIT PERCENT FIELD, USE MAPPER'S DEFUALT VALUE
        })
      //THIS WILL CATCH ALL FIELDS THAT ARE SENT UP BUT ALSO CAN BE EDITED BY THE CUSTOMER.
      } else {
        let string_s = _c[_t.custom_fields[cf].name]
        string_s.substring(0,(_t.custom_fields[cf].avg_text_length ? (_t.custom_fields[cf].avg_text_length.num_chars_per_line < string_s.length ? _t.custom_fields[cf].avg_text_length.num_chars_per_line : string_s.length) : string_s.length))
        _arr.push({
          name:_t.custom_fields[cf].name,
          value:string_s,
          // height: 11,
          editor: 'Customer'
        })
      }
    }
  }
  return _arr
}

export const setClientSearchResults = (searchResults,txt) => {
  return async dispatch => {
    try {
      let r
      let clients = store.getState().newRequest.clients
      if(searchResults && searchResults.length > 0) {
        // let _sel = store.getState().newRequest.clients
        if(clients && clients.length > 0) {
          let found
          for(let i in clients) {
            found = false
            for(let _i in searchResults) {
              if(searchResults[_i].item) {
                if((searchResults[_i].item.Id === clients[i].Id)) {
                  found = true
                  break
                }
              }else {
                if((searchResults[_i].Id === clients[i].Id)) {
                  found = true
                  break
                }
              }
            }
            //ADD CHECKED TO SEARCHED LIST
            if(found === false && clients[i].checked) {
              searchResults.push(clients[i])
            }
          }
          // let filtered = _sel.filter(s => (s.item ? s.item.checked : s.checked))
          // filtered.forEach(e => {
          //   let r = results.find(_e => (_e.item ? _e.item.Id : _e.Id) === (e.item ? e.item.Id : e.Id))
          //   if(!r) results.push(e)
          // })
          // if(filtered && filtered.length > 0) results = results.concat(filtered)
          
          // let sortedResults = results.sort((a,b) => {
          //   if(a.item) {
          //     if(b.item) {
          //       return a.item.name.localeCompare(b.item.name)
          //     }else 
          //       return a.item.name.localeCompare(b.name)
          //   }else {
          //     if(b.item) {
          //       return a.name.localeCompare(b.item.name)
          //     }else
          //       return a.name.localeCompare(b.name)
          //   }
          // })
          // sortedResults = sortedResults.sort((a,b) => {
          // })
          dispatch(_setClientSearchResults(searchResults))
        }else {
          dispatch(_setClientSearchResults(searchResults))
        } 
      }else {
        for(let _i in clients) {
          if(clients[_i].checked) searchResults.push(clients[_i])
        }
        dispatch(_setClientSearchResults(searchResults))
      }
    }catch(err) {
      console.log("err: ", err)
    }
  }
}
